import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { NgxDropzoneService, RejectedFile } from '../ngx-dropzone.service';
import { coerceBooleanProperty, coerceNumberProperty } from '../helpers';
import { NgxDropzonePreviewComponent } from '../ngx-dropzone-preview/ngx-dropzone-preview.component';
import { BehaviorSubject, catchError, Subject } from 'rxjs';

export interface NgxDropzoneChangeEvent {
  source: NgxDropzoneComponent;
  addedFiles: File[];
  rejectedFiles: RejectedFile[];
}

@Component({
  selector: 'ngx-dropzone, [ngx-dropzone]',
  templateUrl: './ngx-dropzone.component.html',
  styleUrls: ['./ngx-dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [NgxDropzoneService],
})
export class NgxDropzoneComponent implements AfterContentInit {
  constructor(@Self() private service: NgxDropzoneService) {}

  @ContentChildren(NgxDropzonePreviewComponent)
  _previewChildren: QueryList<NgxDropzonePreviewComponent> | undefined;

  @ViewChild('fileInput', { static: true }) _fileInput: ElementRef | undefined;

  @Output() readonly change = new EventEmitter<NgxDropzoneChangeEvent>();

  @Input() accept = '*';
  @Input() id?: string;
  @Input('aria-label') ariaLabel?: string;
  @Input('aria-labelledby') ariaLabelledby?: string;
  @Input('aria-describedby') ariaDescribedBy?: string;

  @HostBinding('class.ngx-dz-hovered') _isHovered = false;

  hasPreviews: Subject<boolean> = new BehaviorSubject(false);

  private _disabled = false;

  @Input()
  @HostBinding('class.ngx-dz-disabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._isHovered) {
      this._isHovered = false;
    }
  }

  private _multiple = true;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  private _maxFileSize?: number | null = undefined;

  @Input()
  get maxFileSize(): number {
    return this._maxFileSize ?? 0;
  }

  set maxFileSize(value: number) {
    this._maxFileSize = coerceNumberProperty(value);
  }

  private _expandable = false;

  @Input()
  @HostBinding('class.expandable')
  get expandable(): boolean {
    return this._expandable;
  }

  set expandable(value: boolean) {
    this._expandable = coerceBooleanProperty(value);
  }

  private _disableClick = false;

  @Input()
  @HostBinding('class.unclickable')
  get disableClick(): boolean {
    return this._disableClick;
  }

  set disableClick(value: boolean) {
    this._disableClick = coerceBooleanProperty(value);
  }

  private _processDirectoryDrop = false;

  @Input()
  get processDirectoryDrop(): boolean {
    return this._processDirectoryDrop;
  }

  set processDirectoryDrop(value: boolean) {
    this._processDirectoryDrop = coerceBooleanProperty(value);
  }

  ngAfterContentInit(): void {
    this._previewChildren?.changes.subscribe((previews) => {
      this.hasPreviews.next(previews.length > 0);
    });
  }

  @HostListener('click')
  _onClick() {
    if (!this.disableClick) {
      this.showFileSelector();
    }
  }

  @HostListener('dragover', ['$event'])
  _onDragOver(event: DragEvent) {
    if (this.disabled) {
      return;
    }

    this.preventDefault(event);
    this._isHovered = true;
  }

  @HostListener('dragleave')
  _onDragLeave() {
    this._isHovered = false;
  }

  @HostListener('drop', ['$event'])
  async _onDrop(event: DragEvent) {
    if (this.disabled) {
      return;
    }

    this.preventDefault(event);
    this._isHovered = false;

    let files = event?.dataTransfer?.files;
    if (
      !this.processDirectoryDrop ||
      !DataTransferItem.prototype.webkitGetAsEntry
    ) {
      if (files) this.handleFileDrop(files);
      return;
    }

    const droppedItems = event?.dataTransfer?.items;
    if (droppedItems && droppedItems.length > 0) {
      const { files: droppedFiles, directories: droppedDirectories } =
        this.separateFilesAndDirectories(droppedItems, event);

      const droppedFilesList = new DataTransfer();
      droppedFiles.forEach((file) => droppedFilesList.items.add(file));

      if (!droppedDirectories.length && droppedFilesList.items.length) {
        this.handleFileDrop(droppedFilesList.files);
      } else {
        const allExtractedFiles: File[] =
          await this.extractAllFilesFromDirectories(droppedDirectories);
        allExtractedFiles.forEach((file) => droppedFilesList.items.add(file));
        this.handleFileDrop(droppedFilesList.files);
      }
    }
  }

  showFileSelector() {
    if (!this.disabled) {
      (this._fileInput?.nativeElement as HTMLInputElement).click();
    }
  }

  _onFilesSelected(event: any) {
    const files: FileList = event.target.files;
    this.handleFileDrop(files);

    if (this._fileInput) this._fileInput.nativeElement.value = '';

    this.preventDefault(event);
  }

  private separateFilesAndDirectories(
    items: DataTransferItemList,
    event: DragEvent,
  ) {
    const files: File[] = [];
    const directories: FileSystemDirectoryEntry[] = [];

    for (let i = 0; i < items.length; i++) {
      const entry = items[i].webkitGetAsEntry();
      if (entry?.isFile) {
        const file = event?.dataTransfer?.files?.[i];
        if (file) files.push(file);
      } else if (entry?.isDirectory) {
        directories.push(entry as FileSystemDirectoryEntry);
      }
    }

    return { files, directories };
  }

  private async extractAllFilesFromDirectories(
    directories: FileSystemDirectoryEntry[],
  ) {
    const filesFromDirectories = await Promise.all(
      directories.map((directory) => this.extractFilesFromDirectory(directory)),
    );
    return filesFromDirectories.flat();
  }

  private async extractFilesFromDirectory(
    directory: FileSystemDirectoryEntry,
  ): Promise<File[]> {
    const files: File[] = [];
    const dirReader = directory.createReader();

    const recursiveRead = async (resolve: Function, reject: Function) => {
      try {
        const dirItems: FileSystemEntry[] = await new Promise((res, rej) =>
          dirReader.readEntries(res, rej),
        );
        if (!dirItems.length) {
          resolve(files);
          return;
        }

        for (const item of dirItems) {
          if (item.isFile) {
            const file = await this.getFileFromFileEntry(
              item as FileSystemFileEntry,
            );
            files.push(file);
          }
        }
        recursiveRead(resolve, reject);
      } catch (error) {
        reject(error);
      }
    };

    return new Promise((resolve, reject) => recursiveRead(resolve, reject));
  }

  private async getFileFromFileEntry(
    fileEntry: FileSystemFileEntry,
  ): Promise<File> {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  }

  private handleFileDrop(files: FileList) {
    const result = this.service.parseFileList(
      files,
      this.accept,
      this.maxFileSize,
      this.multiple,
    );

    this.change.next({
      addedFiles: result.addedFiles,
      rejectedFiles: result.rejectedFiles,
      source: this,
    });
  }

  private preventDefault(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
