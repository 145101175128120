<div>
  <label for="combobox" class="block text-sm font-medium text-gray-700"></label>
  <div class="relative mt-1">
    <input
      (keyup)="onKeyPress($event)"
      (focus)="toggleListDisplay(1)"
      id="combobox"
      autocomplete="off"
      type="text"
      [formControl]="comboboxForm"
      class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      role="combobox"
      aria-controls="options"
      aria-expanded="false"
    />
    <button
      (click)="toggle()"
      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
    >
      <svg
        class="h-5 w-5 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <ul
      [hidden]="(showDropdown$ | async) === false"
      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      id="options"
      role="listbox"
    >
      @for (option of filteredItems$ | async; track option) {
        <li
          (click)="select(option)"
          (mouseenter)="active = option"
          (mouseleave)="active = null"
          [ngClass]="{
            'bg-cyan-600': active?.id === option.id,
            'text-white': active?.id === option.id
          }"
          class="hover:text-white hover:bg-cyan-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
          role="option"
          tabindex="-1"
        >
          <span class="block truncate">{{ option.label }}</span>
          @if (selected?.id === option.id) {
            <span
              [ngClass]="{
                'text-indigo-600': active?.id !== option.id,
                'text-white': active?.id == option.id
              }"
              class="absolute inset-y-0 right-0 flex items-center pr-4"
            >
              <svg
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          }
        </li>
      }

      <li
        *ngIf="canAddItem$ | async"
        (click)="addNewItem()"
        class="hover:text-white hover:bg-cyan-600 relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900"
        (mouseenter)="newItem = true"
        (mouseleave)="newItem = null"
        [ngClass]="{
          'bg-cyan-600': newItem,
          'text-white': newItem
        }"
      >
        <span class="block truncate">{{ addItemLabel | translate }}</span>
        <span
          [ngClass]="{
            'text-indigo-600': newItem,
            'text-white': newItem
          }"
          class="absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </span>
      </li>
    </ul>
  </div>
</div>
