<div class="grid grid-cols-3 gap-3 sm:grid-cols-6 mt-2">
  <!--
  In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
  Active: "ring-2 ring-offset-2 ring-indigo-500"
  Checked: "bg-indigo-600 border-transparent text-white hover:bg-indigo-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
  -->
  @for (choice of choices; track choice) {
    <label
      [ngClass]="{
        'ring-2 ring-offset-2 ring-indigo-500 bg-indigo-600 border-transparent text-white hover:bg-indigo-700':
          isSelected(choice)
      }"
      class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none"
    >
      <input
        (click)="toggleValue(choice)"
        [disabled]="disabled"
        [value]="choice.value"
        class="sr-only"
        name="memory-option"
        type="radio"
      />
      <span id="memory-option-0-label">{{ choice.label }}</span>
    </label>
  }
</div>
