import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TenantService } from '../../services/tenant.service';
import { SignInWithGoogleAction } from '../../actions/authentication.action';
import { Select, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { AuthenticationState } from '../../store/authentication.store';
const appVersion = require('../../../../../../../../package.json').version;

@Component({
  selector: 'app-signin',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  favoriteOrganizations: string[] = [];

  @Select(AuthenticationState.googleProviderEnabled)
  googleProviderEnabled$!: Observable<boolean>;

  organizationFormControl = new FormControl(
    this.tenantService.currentOrganizationSlug,
    [Validators.required],
  );

  constructor(
    public authService: AuthService,
    private tenantService: TenantService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.signInForm = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }
  appVersion = appVersion;
  readonly signInForm;

  async signInWithGoogle() {
    this.store.dispatch(new SignInWithGoogleAction());
  }

  async forgotPassword() {
    const { email } = this.signInForm.value;
    if (email) {
      await this.authService.forgotPassword(email);
    } else {
      this.signInForm.controls.email.setErrors({ emailRequiredForReset: true });
    }
  }

  async onSubmit() {
    const { email, password } = this.signInForm.value;
    if (email && password) {
      this.authService.signInWithEmailAndPassword(email, password);
    }
  }

  register() {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      'registration',
    ]);
  }

  ngOnInit(): void {
    this.favoriteOrganizations = this.tenantService.getFavoriteOrganizations();
    this.organizationFormControl.valueChanges.subscribe((value) => {
      this.store.dispatch(new Navigate([value, 'auth', 'sign-out']));
    });
  }
}
