<input type="file" (change)="onFileSelected($event)" accept="image/*" />
<section class="area" id="resize-area" #droppedImageParent>
  <div
    #droppedImage
    (ngxDragged)="onDragged($event)"
    (ngxResized)="onResized($event)"
    [ngStyle]="{ 'background-image': backgroundImg$ | async }"
    ngxDragBoundary="#resize-area"
    class="rect offset-small"
    ngxResize
    ngxDrag
    [style.width]="(position$ | async)?.width"
    [style.height]="(position$ | async)?.height"
    [style.top]="(position$ | async)?.top"
    [style.left]="(position$ | async)?.left"
    ngxDragPositionStrategy="relative"
    [ngxResizeMinWidth]="50"
    [ngxResizeMinHeight]="50"
    [ngxResizeAspectRatio]="(aspectRatio$ | async) ?? 100"
    ngxResizeBoundary="#resize-area"
  >
    <div
      [ngxResizeHandle]="handleType.TopLeft"
      data-resize-handle="TopLeft"
    ></div>
    <div [ngxResizeHandle]="handleType.Top" data-resize-handle="Top"></div>
    <div
      [ngxResizeHandle]="handleType.TopRight"
      data-resize-handle="TopRight"
    ></div>
    <div [ngxResizeHandle]="handleType.Right" data-resize-handle="Right"></div>
    <div
      [ngxResizeHandle]="handleType.BottomRight"
      data-resize-handle="BottomRight"
    ></div>
    <div
      [ngxResizeHandle]="handleType.Bottom"
      data-resize-handle="Bottom"
    ></div>
    <div
      [ngxResizeHandle]="handleType.BottomLeft"
      data-resize-handle="BottomLeft"
    ></div>
    <div [ngxResizeHandle]="handleType.Left" data-resize-handle="Left"></div>
  </div>
</section>
