import { Component, forwardRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { GuidedMissionModel } from '@freddy/models';

@Component({
  selector: 'app-guided-challenge-input',
  templateUrl: './guided-challenge-input.component.html',
  styleUrls: ['./guided-challenge-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GuidedChallengeInputComponent),
      multi: true,
    },
  ],
})
export class GuidedChallengeInputComponent
  implements ControlValueAccessor, OnInit
{
  disabled = false;
  guidedMissionForm = new FormGroup({
    password: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(3)],
      nonNullable: true,
    }),
  });

  onChange = (guided: GuidedMissionModel | null) => {};

  onTouched = () => {};

  ngOnInit(): void {
    this.guidedMissionForm.valueChanges.subscribe((value) => {
      if (this.guidedMissionForm.status === 'VALID') {
        this.onChange(this.guidedMissionForm.value as GuidedMissionModel);
      } else {
        this.onChange(null);
      }
    });
  }

  registerOnChange(
    onChange: (minMax: GuidedMissionModel | null) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(guided: GuidedMissionModel) {
    if (guided) {
      this.guidedMissionForm.setValue(guided);
    }
  }
}
