<input
  #fileInput
  type="file"
  [id]="id"
  [multiple]="multiple"
  [accept]="accept"
  [disabled]="disabled"
  (change)="_onFilesSelected($event)"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-describedby]="ariaDescribedBy"
/>
@if (!(hasPreviews | async)) {
  <ng-content select="ngx-dropzone-label"></ng-content>
}
<ng-content select="ngx-dropzone-preview"></ng-content>
<ng-content></ng-content>
