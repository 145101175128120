<!-- This example requires Tailwind CSS v2.0+ -->
<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-xl font-semibold text-gray-900">List of choices</h1>
      <p class="mt-2 text-sm text-gray-700">
        List of choices with the answer selected
      </p>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      <button
        type="button"
        (click)="addLine()"
        class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
      >
        Add a choice
      </button>
    </div>
  </div>
  <div class="mt-8 flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
        >
          <form [formGroup]="dynamicForm">
            <table
              class="min-w-full divide-y divide-gray-300"
              formArrayName="choices"
            >
              <thead class="bg-gray-50">
                <tr class="divide-x divide-gray-200">
                  <th></th>
                  <th scope="col" class="text-sm font-semibold text-gray-900">
                    Answer
                  </th>
                  @for (lang of languages; track lang) {
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {{ lang | uppercase }}
                    </th>
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                @for (line of choices.controls; track line; let i = $index) {
                  <tr class="divide-x divide-gray-200" [formGroupName]="i">
                    <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                      {{ i + 1 }}.
                    </td>
                    <td
                      class="whitespace-nowrap p-1 text-sm text-gray-500 text-center"
                    >
                      <input
                        type="radio"
                        [formControl]="selectedLineControl"
                        [value]="i"
                      />
                    </td>
                    @for (lang of languages; track lang) {
                      <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                        <input
                          type="text"
                          [formControlName]="lang"
                          [placeholder]="lang"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </td>
                    }
                    <td class="whitespace-nowrap p-2 text-sm text-gray-500">
                      <span
                        class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                        (click)="removeLine(i)"
                      >
                        Remove
                      </span>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
