import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsUtils } from '../../../utils/validators.utils';
import { FirebaseError } from '@angular/fire/app';
import { FirebaseErrorCode } from '../../../firebase/error/error.code';
import { Store } from '@ngxs/store';
import { SignInWithGoogleAction } from '../../actions/authentication.action';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TenantService } from '../../services/tenant.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  constructor(
    public auth: AuthService,
    private readonly store: Store,
    private readonly tenantService: TenantService,
    private readonly router: Router,
  ) {
    this.registrationForm = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      password_confirmation: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
    this.registrationForm.addValidators(
      ValidatorsUtils.createCompareValidator(
        this.registrationForm.controls.password,
        this.registrationForm.controls.password_confirmation,
      ),
    );
  }

  readonly registrationForm;

  async onSubmit() {
    const { email, password } = this.registrationForm.value;
    if (email && password) {
      try {
        await this.auth.createUserWithEmailAndPassword(email, password);
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.code === FirebaseErrorCode.EmailAlreadyTaken) {
            this.registrationForm.controls.email.setErrors({
              emailAlreadyExists: true,
            });
          }
        }
      }
    }
  }

  goToLogin() {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      'sign-in',
    ]);
  }
}
