<div>
  <h3 class="text-lg font-medium leading-6 text-gray-900">Guided</h3>
  <p class="mt-1 text-sm text-gray-500">
    Complete the information about the mission
  </p>
</div>
<form
  [formGroup]="guidedMissionForm"
  class="flex flex-1 flex-col justify-between"
>
  <div class="space-y-6 pt-6 pb-5">
    <div>
      <label class="block text-sm font-medium text-gray-900" for="password">
        Password
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="password"
          id="password"
          type="text"
        />
        <p class="mt-2 text-sm text-gray-500">
          Password to enter during the game to be able to evaluate the mission
        </p>
      </div>
    </div>
  </div>
</form>
