<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img
      alt="Your Company"
      class="mx-auto h-12 w-auto"
      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
    />
    <h2
      class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
    >
      {{ 'auth.registrationComponent.registerNewAccount' | translate }}
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="registrationForm"
        action="#"
        class="space-y-6"
        method="POST"
      >
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ 'auth.registrationComponent.emailAddress' | translate }}
          </label>
          <div class="mt-1">
            <input
              autocomplete="email"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              formControlName="email"
              id="email"
              name="email"
              required
              type="email"
            />
          </div>
          <!-- Error Message -->
          <p
            *ngIf="
              registrationForm.controls.email.errors?.['emailAlreadyExists']
            "
            class="mt-2 text-sm text-red-600"
            id="email-error"
          >
            {{ 'auth.registrationComponent.emailAlreadyTaken' | translate }}
          </p>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ 'auth.registrationComponent.password' | translate }}
          </label>
          <div class="mt-1">
            <input
              autocomplete="current-password"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              formControlName="password"
              id="password"
              minlength="8"
              name="password"
              required
              type="password"
            />
          </div>
        </div>

        <div>
          <label
            for="password_confirmation"
            class="block text-sm font-medium text-gray-700"
          >
            {{ 'auth.registrationComponent.confirmPassword' | translate }}
          </label>
          <div class="mt-1">
            <input
              autocomplete="current-password"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              formControlName="password_confirmation"
              id="password_confirmation"
              minlength="8"
              name="password_confirmation"
              required
              type="password"
            />
          </div>
        </div>

        <div>
          <button
            [disabled]="!registrationForm.valid"
            class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm disabled:opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="submit"
          >
            {{ 'auth.registrationComponent.register' | translate }}
          </button>
        </div>
      </form>

      <div class="mt-6">
        <div class="flex justify-end">
          <div class="text-sm">
            <a
              class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              (click)="goToLogin()"
            >
              {{ 'auth.registrationComponent.alreadyHaveAccount' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
