import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { TabItemComponent } from '../tab-item/tab-item.component';
import { Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterContentInit, AfterContentChecked {
  constructor(private _change: ChangeDetectorRef) {}

  @ContentChildren(TabItemComponent)
  tabs: QueryList<TabItemComponent> | undefined;
  tabItems$: Observable<TabItemComponent[]> | undefined;
  activeTab: TabItemComponent | undefined;
  @Output()
  indexPositionEmitter = new EventEmitter<number>();

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs?.changes
      .pipe(startWith(''))
      .pipe(delay(0))
      .pipe(map(() => this.tabs?.toArray() ?? []));
  }

  ngAfterContentChecked() {
    //choose the default tab
    // we need to wait for a next VM turn,
    // because Tab item content, will not be initialized yet
    if (!this.activeTab) {
      Promise.resolve().then(() => {
        this.activeTab = this.tabs?.first;
        if (this.activeTab) this.activeTab.isActive = true;
      });
    }
  }

  selectNextTab() {
    for (let index = 0; index < (this.tabs?.length ?? 0); index++) {
      if (this.tabs?.get(index)?.isActive) {
        const nextIndex = index + 1;
        let nextTab = this.tabs?.get(nextIndex);
        if (nextTab) {
          this.selectTab(nextTab);
          this._change.detectChanges();
        }
        return;
      }
    }
  }

  selectPrevTab() {
    for (let index = 0; index < (this.tabs?.length ?? 0); index++) {
      if (this.tabs?.get(index)?.isActive) {
        const prevIndex = index - 1;
        let prevTab = this.tabs?.get(prevIndex);
        if (prevTab) {
          this.selectTab(prevTab);
          this._change.detectChanges();
        }
        return;
      }
    }
  }

  selectTab(tabItem: TabItemComponent) {
    if (this.activeTab === tabItem) {
      return;
    }
    if (this.activeTab) {
      this.activeTab.isActive = false;
    }
    this.activeTab = tabItem;
    tabItem.isActive = true;
    this.indexPositionEmitter.emit(this.getCurrentTabIndex());
  }

  private getCurrentTabIndex(): number {
    for (let index = 0; index < (this.tabs?.length ?? 0); index++) {
      if (this.tabs?.get(index)?.isActive) {
        return index;
      }
    }
    return 0;
  }
}
