import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Locale } from 'locale-enum';
import { MultipleChoicesMissionModel, TranslatedContent } from '@freddy/models';

@Component({
  selector: 'app-multi-choices-input',
  templateUrl: './multi-choices-input.component.html',
  styleUrls: ['./multi-choices-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiChoicesInputComponent),
      multi: true,
    },
  ],
})
export class MultiChoicesInputComponent
  implements ControlValueAccessor, OnInit
{
  constructor(private formBuilder: FormBuilder) {}

  @Input()
  languages: Locale[] | null = [Locale.fr, Locale.en];
  dynamicForm!: FormGroup;
  selectedLineControl: FormControl = new FormControl(-1, Validators.min(0)); // Initialize to -1, indicating no line selected

  disabled = false;

  get choices() {
    return this.dynamicForm.get('choices') as FormArray;
  }

  onChange = (minMax: MultipleChoicesMissionModel | null) => {};

  onTouched = () => {};

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
      choices: this.formBuilder.array([], Validators.minLength(2)),
      selectedLine: this.selectedLineControl,
    });
    if (this.choices.length === 0) {
      this.addLine();
    }

    this.dynamicForm.valueChanges.subscribe((value) => {
      if (this.dynamicForm.status === 'VALID') {
        this.onChange(value as MultipleChoicesMissionModel);
      } else {
        this.onChange(null);
      }
    });
  }

  registerOnChange(
    onChange: (minMax: MultipleChoicesMissionModel | null) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(multiplesChoices: MultipleChoicesMissionModel) {
    if (multiplesChoices && this.languages) {
      // Filter choices based on available languages
      const filteredChoices = multiplesChoices.choices.map((choice) => {
        const filteredChoice: TranslatedContent<string | null> = {};
        this.languages?.forEach((lang) => {
          filteredChoice[lang] = choice[lang] ?? null;
        });
        return filteredChoice;
      });

      // Create a new object with filtered choices
      const filteredMultiplesChoices: MultipleChoicesMissionModel = {
        choices: filteredChoices as any,
        selectedLine: multiplesChoices.selectedLine,
      };

      // Adjust the form array size
      while (this.choices.length < filteredMultiplesChoices.choices.length) {
        this.addLine();
      }
      while (this.choices.length > filteredMultiplesChoices.choices.length) {
        this.choices.removeAt(this.choices.length - 1);
      }

      // Set the filtered value
      this.dynamicForm.setValue(filteredMultiplesChoices);
    }
  }

  addLine() {
    const newLine = this.formBuilder.group({});
    this.languages?.forEach((lang) => {
      newLine.addControl(
        lang,
        this.formBuilder.control('', Validators.required),
      );
    });
    this.choices.push(newLine);
  }

  removeLine(index: number) {
    this.choices.removeAt(index);
    if (this.selectedLineControl.value === index) {
      this.selectedLineControl.setValue(-1); // Reset selection if the selected line is removed
    }
  }
}
