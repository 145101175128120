<div>
  <div class="">
    <div class="border-b border-gray-200">
      <nav aria-label="Tabs" class="-mb-px flex space-x-8">
        <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
        @for (item of tabItems$ | async; track item) {
          <a
            (click)="selectTab(item)"
            [ngClass]="{
              'border-indigo-500 text-indigo-600': item.isActive,
              'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                !item.isActive
            }"
            class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
          >
            @if (item.labelComponent) {
              <ng-container
                *ngTemplateOutlet="item.labelComponent.labelContent"
              ></ng-container>
            }
            @if (!item.labelComponent) {
              {{ item.label }}
            }
          </a>
        }
      </nav>
    </div>
  </div>
</div>

<div class="tabs-body">
  @if (activeTab && activeTab.bodyComponent) {
    <ng-container
      *ngTemplateOutlet="activeTab.bodyComponent.bodyContent"
    ></ng-container>
  }
</div>
