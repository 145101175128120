import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tab-body',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBodyComponent implements OnInit {
  constructor() {}

  @ViewChild(TemplateRef)
  bodyContent: TemplateRef<any> | null = null;

  ngOnInit(): void {}
}
