import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select } from 'async';
import { Observe } from 'common';
import { Observable } from 'rxjs';

export interface RadioChoice<T = any> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true,
    },
  ],
})
export class RadioInputComponent implements ControlValueAccessor {
  @Input()
  choices: RadioChoice[] | undefined | null = [];

  @Observe('choices')
  choices$!: Observable<any>;

  @Input()
  multiple = false;

  @Output()
  onSelectEvent = new EventEmitter<any>();

  selected: any[] = [];

  disabled = false;

  onChange = (selected: any[] | null) => {};
  onTouched = () => {};

  writeValue(values: any[] = []): void {
    this.selected = Array.isArray(values) ? [...values] : [values];
  }

  registerOnChange(onChange: (selected: RadioChoice[] | null) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isSelected(choice: RadioChoice) {
    const isSelected = this.selected.find((s) => s == choice.value);
    return isSelected;
  }

  toggleValue(choice: RadioChoice) {
    this.onTouched();
    if (this.isSelected(choice)) {
      this.selected = this.selected.filter((v) => v !== choice.value);
      this.onSelectEvent.emit(null);
    } else {
      if (!this.multiple && this.selected?.length > 0) {
        this.selected = [];
      }
      this.onSelectEvent.emit(choice.value);
      this.selected.push(choice.value);
    }
    this.onChange(this.selected);
  }
}
