import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tab-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-template><ng-content></ng-content></ng-template>  ',
})
export class TabLabelComponent implements OnInit {
  constructor() {}

  @ViewChild(TemplateRef)
  labelContent: TemplateRef<any> | null = null;

  ngOnInit(): void {}
}
