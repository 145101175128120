<div>
  <h3 class="text-lg font-medium leading-6 text-gray-900">Min/Max</h3>
  <p class="mt-1 text-sm text-gray-500">
    Complete the information about the mission
  </p>
</div>
<form [formGroup]="minMaxForm" class="flex flex-1 flex-col justify-between">
  <div class="space-y-6 pt-6 pb-5">
    <div>
      <label class="block text-sm font-medium text-gray-900" for="max">
        Maximum value
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="max"
          id="max"
          onfocus="this.select()"
          type="number"
        />
      </div>
    </div>

    <div>
      <label class="block text-sm font-medium text-gray-900" for="min">
        Minimum value
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="min"
          id="min"
          onfocus="this.select()"
          type="number"
        />
      </div>
    </div>
    <div>
      <label class="block text-sm font-medium text-gray-900" for="answer">
        Answer
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="answer"
          id="answer"
          onfocus="this.select()"
          type="number"
        />
      </div>
    </div>
    <div>
      <label class="block text-sm font-medium text-gray-900" for="defaultValue">
        Default value
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="defaultValue"
          id="defaultValue"
          onfocus="this.select()"
          type="number"
        />
      </div>
    </div>
    <div>
      <label class="block text-sm font-medium text-gray-900" for="margin">
        Margin
      </label>
      <div class="mt-1">
        <input
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formControlName="margin"
          id="margin"
          onfocus="this.select()"
          type="number"
        />
      </div>
    </div>
    @if (minMaxForm.errors?.['MIN_MAX'] && minMaxForm.touched) {
      <p class="mt-2 text-sm text-red-600" id="min-max-error">
        {{ minMaxForm.errors?.['MIN_MAX'] }}
      </p>
    }
  </div>
</form>
