import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Team } from '@freddy/models';

@Component({
  selector: 'tmu-team-map-marker',
  templateUrl: './team-map-marker.component.html',
  styleUrls: ['./team-map-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMapMarkerComponent {
  @Input() team: Team | undefined;
}
