import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MinMaxMissionModel } from '@freddy/models';

@Component({
  selector: 'app-min-max-challenge-input',
  templateUrl: './min-max-challenge-input.component.html',
  styleUrls: ['./min-max-challenge-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinMaxChallengeInputComponent),
      multi: true,
    },
  ],
})
export class MinMaxChallengeInputComponent
  implements ControlValueAccessor, OnInit
{
  disabled = false;
  onChange = (minMax: MinMaxMissionModel | null) => {};
  onTouched = () => {};

  ngOnInit(): void {
    this.minMaxForm.valueChanges.subscribe((value) => {
      if (this.minMaxForm.status === 'VALID') {
        this.onChange(value as MinMaxMissionModel);
      } else {
        this.onChange(null);
      }
    });
  }

  minMaxValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const min = control.get('min');
    const max = control.get('max');
    const answer = control.get('answer');
    const defaultValue = control.get('defaultValue');
    const margin = control.get('margin');
    if (min && max && !(min.value < max.value)) {
      return {
        MIN_MAX: 'Max should be bigger than min',
      };
    }
    if (
      min &&
      max &&
      defaultValue &&
      !(min.value <= defaultValue.value && max.value >= defaultValue.value)
    ) {
      return {
        MIN_MAX: 'Default value should be between min & max',
      };
    }

    if (
      min &&
      max &&
      answer &&
      !(min.value <= answer.value && answer.value <= max.value)
    ) {
      return {
        MIN_MAX: 'Answer should be between min & max',
      };
    }

    if (min && max && margin && !(max.value - min.value > margin.value)) {
      return {
        MIN_MAX: 'Margin should be less then max - min',
      };
    }
    return null;
  };

  minMaxForm = new FormGroup(
    {
      min: new FormControl<number>(0, {
        validators: [Validators.pattern(/^\d+$/), Validators.required],
      }),
      max: new FormControl<number>(0, {
        validators: [Validators.pattern(/^\d+$/), Validators.required],
        nonNullable: true,
      }),
      answer: new FormControl<number>(0, {
        validators: [Validators.pattern(/^\d+$/), Validators.required],
        nonNullable: true,
      }),
      defaultValue: new FormControl<number>(0, {
        validators: [Validators.pattern(/^\d+$/), Validators.required],
        nonNullable: true,
      }),
      margin: new FormControl<number>(0, {
        validators: [Validators.pattern(/^\d+$/), Validators.required],
        nonNullable: true,
      }),
    },
    { validators: this.minMaxValidator },
  );

  registerOnChange(
    onChange: (minMax: MinMaxMissionModel | null) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(minMax: MinMaxMissionModel) {
    if (minMax) {
      this.minMaxForm.setValue(minMax);
    }
  }
}
