@if (team) {
  <div class="padding-marker">
    <svg
      class="custom-team"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width="80"
      height="80"
    >
      <ellipse
        xmlns="http://www.w3.org/2000/svg"
        class="d"
        cx="250"
        cy="443.77"
        rx="97.02"
        ry="42.13"
      />
      <path
        [style.fill]="team.color + '!important'"
        class="custom-team-2"
        d="m383.22,321.66c32.48-34.63,51.65-81.89,49.23-133.65-4.47-95.74-83.76-172.42-179.59-173.89-102.19-1.56-185.52,80.81-185.52,182.64,0,48.32,18.8,92.23,49.44,124.9h-.04s.79.78.79.78c3.12,3.29,6.33,6.5,9.68,9.55l107.45,105.94c8.51,8.39,22.17,8.39,30.68,0l107.45-105.94c3.36-3.05,6.56-6.26,9.68-9.55l.79-.78h-.04Z"
      />
      <circle class="d" cx="250" cy="196.76" r="154.49" />
    </svg>
    <img
      class="absolute mt-2 top-0 left-[50%] -translate-x-1/2 rounded-full"
      [ngSrc]="team.teamPhoto"
      priority
      tmuImageFallback
      width="47"
      height="47"
      [alt]="'Photo team ' + team.teamName"
    />
  </div>
}
