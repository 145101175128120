<main
  class="min-h-full bg-cover bg-top sm:bg-top"
  style="background-image: url('./assets/background.jpeg')"
>
  <div
    class="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
  >
    <h1 class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
      {{ 'auth.verifyEmailComponent.beforeContinuing' | translate }}
    </h1>
    <p class="mt-2 text-lg font-medium text-black text-opacity-50">
      {{ 'auth.verifyEmailComponent.verifyEmail' | translate }}
    </p>
    <div class="mt-6">
      <a
        class="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
        href="#"
      >
        {{ 'auth.verifyEmailComponent.goBackHome' | translate }}
      </a>
      <a
        (click)="logout()"
        class="ml-4 inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
        href="#"
      >
        {{ 'auth.verifyEmailComponent.logout' | translate }}
      </a>
    </div>
  </div>
</main>
