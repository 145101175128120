import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FileSelectResult } from '../../../dropzone/ngx-dropzone.service';

@Component({
  selector: 'app-dropbox-input',
  templateUrl: './dropbox-input.component.html',
  styleUrls: ['./dropbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropboxInputComponent),
      multi: true,
    },
  ],
})
export class DropboxInputComponent implements ControlValueAccessor {
  @Input()
  multiple = false;

  files: File[] = [];
  files$: BehaviorSubject<File[]> = new BehaviorSubject([] as File[]);
  isDisabled = false;

  onChange = (files: File[]) => {};
  onTouched = () => {};

  registerOnChange(onChange: (files: File[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(files: File[]): void {
    this.files = files ? [...files.filter((f) => f instanceof File)] : [];
    this.files$.next(this.files);
  }

  onSelect(event: FileSelectResult) {
    this.files.push(...event.addedFiles);
    this.onChange(this.files);
    this.files$.next(this.files);
  }

  onRemove(event: File) {
    this.files.splice(this.files.indexOf(event), 1);
    this.onChange(this.files);
    this.files$.next(this.files);
  }
}
