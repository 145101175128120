import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  from,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FileUtils,
  ImageCacheService,
  NgxDrag,
  NgxResize,
  NgxResizeHandleType,
  ObjectUtils,
} from 'common';
import { ArMissionModel } from '@freddy/models';

@Component({
  selector: 'app-ar-challenge-input',
  templateUrl: './ar-challenge-input.component.html',
  styleUrls: ['./ar-challenge-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArChallengeInputComponent),
      multi: true,
    },
  ],
})
export class ArChallengeInputComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @ViewChild('droppedImage', { static: false })
  droppedImage?: ElementRef<HTMLElement>;
  @ViewChild('droppedImageParent') droppedImageParent?: ElementRef<HTMLElement>;

  readonly handleType = NgxResizeHandleType;

  disabled = false;

  arMissionForm = new FormGroup({
    image: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  position$ = new BehaviorSubject<{
    top: number;
    height: number;
    left: number;
    width: number;
  }>({ top: 50, height: 50, left: 50, width: 50 });

  imageSrc$ = new BehaviorSubject<string | ArrayBuffer | null>(null);

  backgroundImg$ = this.imageSrc$.pipe(
    map((src) => (src ? `url(${src})` : 'none')),
  );

  private readonly destroy$ = new Subject<void>();
  protected readonly aspectRatio$ = new BehaviorSubject(100);
  private readonly MAX_SIZE = 600;

  constructor(
    private renderer: Renderer2,
    private imageCacheService: ImageCacheService,
  ) {}

  ngAfterViewInit(): void {
    combineLatest([this.position$, this.imageSrc$, this.aspectRatio$])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe(([position, _]) => {
        this.updateImageStyles(position);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    this.readAndResizeImage(file);
  }

  onDragged(_: NgxDrag): void {
    this.updateImagePosition();
  }

  onResized(event: NgxResize): void {
    if (event.nativeEvent) {
      this.updateImagePosition();
    }
  }

  // ControlValueAccessor methods
  onChange = (_: ArMissionModel | null) => {};
  onTouched = () => {};

  writeValue(ar: ArMissionModel | null): void {
    if (ar) {
      this.arMissionForm.patchValue(ar);
      this.loadImage(ar.image, ar.width, ar.height);
      this.position$.next({
        top: ar.top,
        height: ar.height,
        left: ar.left,
        width: ar.width,
      });
    }
  }

  registerOnChange(fn: (ar: ArMissionModel | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateImageStyles(position: {
    top: number;
    height: number;
    left: number;
    width: number;
  }): void {
    const element = this.droppedImage?.nativeElement;
    if (element) {
      requestAnimationFrame(() => {
        this.renderer.setStyle(element, 'width', `${position.width}px`);
        this.renderer.setStyle(element, 'left', `${position.left}px`);
        this.renderer.setStyle(element, 'top', `${position.top}px`);
        this.renderer.setStyle(element, 'height', `${position.height}px`);
      });
    }
  }

  private readAndResizeImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => this.resizeImage(img, file.type);
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  private resizeImage(img: HTMLImageElement, fileType: string): void {
    const aspectRatio = img.width / img.height;
    this.setAspectRatio(img.width, img.height);

    let newWidth = img.width;
    let newHeight = img.height;

    if (newWidth > this.MAX_SIZE || newHeight > this.MAX_SIZE) {
      if (newWidth > newHeight) {
        newWidth = this.MAX_SIZE;
        newHeight = Math.round(newWidth / aspectRatio);
      } else {
        newHeight = this.MAX_SIZE;
        newWidth = Math.round(newHeight * aspectRatio);
      }
    }

    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;

    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, newWidth, newHeight);

    this.position$.next({
      top: 0,
      width: newWidth,
      height: newHeight,
      left: 0,
    });

    this.imageSrc$.next(canvas.toDataURL(fileType));
  }

  private loadImage(image: string, width: number, height: number): void {
    const imageObservable: Observable<string | undefined> =
      FileUtils.isBase64Image(image) || ObjectUtils.isValidUrl(image)
        ? new Observable((observer) => {
            observer.next(image);
            observer.complete();
          })
        : from(this.imageCacheService.getImage(image));

    imageObservable.pipe(takeUntil(this.destroy$)).subscribe((loadedImage) => {
      if (loadedImage) {
        this.imageSrc$.next(loadedImage);
        this.setAspectRatio(width, height);
      }
    });
  }

  private setAspectRatio(width: number, height: number): void {
    const aspectRatio = (height / width) * 100;
    this.aspectRatio$.next(aspectRatio);
  }

  private updateImagePosition(): void {
    if (this.droppedImage && this.droppedImageParent) {
      const childRect = this.droppedImage.nativeElement.getBoundingClientRect();
      const parentRect =
        this.droppedImageParent.nativeElement.getBoundingClientRect();

      const positionRelative = {
        top: childRect.top - parentRect.top,
        left: childRect.left - parentRect.left,
        width: childRect.width,
        height: childRect.height,
      };
      this.onChange({
        image: this.imageSrc$.value as string,
        ...positionRelative,
      });
    }
  }
}
