import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../../core/auth/store/authentication.store';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective implements OnInit, OnDestroy {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store,
  ) {}

  // the role(s) the user must have
  @Input() appHasRole?: string | string[];
  stop$ = new Subject<void>();
  isVisible = false;

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    this.store
      .select(AuthenticationState.user)
      .pipe(takeUntil(this.stop$))
      .subscribe((user) => {
        this.updateView(user);
      });
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private updateView(user: { [key: string]: any } | null): void {
    if (!this.appHasRole) {
      this.showView();
      return;
    }

    const isDefined = (value: any) =>
      value !== null && value !== undefined && value !== false;

    let hasRole = false;
    if (typeof this.appHasRole === 'string') {
      hasRole = isDefined(user?.[this.appHasRole]);
    } else if (Array.isArray(this.appHasRole)) {
      hasRole = this.appHasRole.some((role) => isDefined(user?.[role]));
    }

    if (hasRole) {
      this.showView();
    } else {
      this.hideView();
    }
  }

  // Method to show the view
  private showView(): void {
    if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  // Method to hide the view
  private hideView(): void {
    if (this.isVisible) {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
}
