import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
} from '@angular/core';
import { TabLabelComponent } from '../tab-label/tab-label.component';
import { TabBodyComponent } from '../tab-body/tab-body.component';

@Component({
  selector: 'app-tab-item',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabItemComponent implements OnInit {
  constructor() {}

  @Input()
  label: string | undefined;
  @Input()
  isActive: boolean | undefined;
  @ContentChild(TabBodyComponent)
  bodyComponent: TabBodyComponent | undefined;
  @ContentChild(TabLabelComponent)
  labelComponent: TabLabelComponent | undefined;

  ngOnInit(): void {}
}
