<!-- use maxFileSize from config-->
<div
  #drop
  (change)="onSelect($event)"
  [disableClick]="true"
  [disabled]="isDisabled"
  [multiple]="multiple"
  accept="image/jpeg,image/jpg,image/png,image/gif, image/webp"
  class="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
  ngx-dropzone
>
  <div class="space-y-1 text-center">
    <svg
      aria-hidden="true"
      class="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 48 48"
    >
      <path
        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
    <div class="flex text-sm text-gray-600">
      <label
        (click)="drop.showFileSelector()"
        class="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
      >
        <span>Upload a file</span>
      </label>
      <p class="pl-1">or drag and drop</p>
    </div>
    <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
  </div>
  @for (f of files$ | async; track f) {
    <ngx-dropzone-image-preview
      (removed)="onRemove(f)"
      [file]="f"
      [removable]="true"
      ngProjectAs="ngx-dropzone-preview"
    ></ngx-dropzone-image-preview>
  }
</div>
